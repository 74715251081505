@media (min-width:1200px) and (max-width:1380) {
  .ai-power-dashboard .trust-by.gray-theme iframe {
    min-height: auto !important;
  }
}

@media (min-width:1200px) {
  .service_item h6 {
    height: auto !important;
  }

  .main-header-front {
    margin-bottom: 0px !important;
    height: auto !important;
  }

  .navbar-light .navbar-nav.menu_nav .nav-link {
    color: var(--blue) !important;
    font-size: 0.9rem !important;
    padding: 10px 15px !important;
  }


  a.nav-link.btnPrimary {
    font-size: 0.9rem !important;
    padding: 8px 10px !important;
  }

  .navbar-light .navbar-nav.right-nav .nav-link.linktext {
    margin: 0 10px !important;
    font-size: 0.9rem !important;
  }

  .main-header-front {
    margin-bottom: 0px !important;
    height: auto !important;
  }

  .navbar-light .navbar-nav.menu_nav .nav-link {
    color: var(--blue) !important;
    font-size: 0.9rem !important;
    /* padding: 10px !important; */
  }


  a.nav-link.btnPrimary {
    font-size: 0.9rem !important;
    padding: 8px 10px !important;
  }

  .navbar-light .navbar-nav.right-nav .nav-link.linktext {
    margin: 0 5px 0 20px !important;
    font-size: 0.9rem !important;
  }

  .underMantanence .overlay {
    width: 900px !important;
    height: 900px !important;
    min-height: auto !important;
  }

  .powerTitle {
    font-size: 1.25rem !important;
  }



}

/* XXL Screen */
@media (min-width: 1200px) and (max-width:1350px) {
  .affiliateFAQ {
    padding: 40px;
  }

  .affiliateTop .affiliate_top_image {
    height: 100% !important;
    min-height: auto !important;
  }

  .pricing_item .pricing_item_price {
    padding: 0 5px !important;
  }

  .pricing_item {
    padding: 10px !important;
  }

  .select_plan {
    width: 100% !important;
  }

  .pricing_item .pricing_item_price .details ul li {
    font-size: 0.8rem;
  }
}

/* XXL Screen */
@media (max-width: 1200px) {
  .main-header-front {
    margin-bottom: 0px !important;
    height: auto !important;
  }

  .navbar-light .navbar-nav.menu_nav .nav-link {
    color: var(--blue) !important;
    font-size: 0.9rem !important;
    /* padding: 10px !important; */
  }


  a.nav-link.btnPrimary {
    font-size: 0.9rem !important;
    padding: 8px 10px !important;
  }

  .navbar-light .navbar-nav.right-nav .nav-link.linktext {
    margin: 0 5px 0 20px !important;
    font-size: 0.9rem !important;
  }

  .react-player {
    width: 100% !important;
    /* height: 480px !important; */
  }

  /* under mantanence */
  .underMantanence {
    height: 100vh;
    min-height: auto !important;
  }

  .underMantanence .overlay {
    width: 750px !important;
    height: 750px !important;
    min-height: auto !important;
  }

  .underMantanence .overlay .overlay1 {
    padding: 60px !important;
    width: 100% !important;
    height: 100% !important;
  }



  .underMantanence .text_section h3 {
    font-size: 2.5rem !important;
    text-align: center;
    margin: 20px 0 !important;
  }

  .underMantanence .text_section p {
    font-size: 1.5rem !important;
    text-align: center;
  }


}

/*  XL Screen */
@media (min-width: 1025px) and (max-width: 1199px) {


  .main-top-section {
    margin-top: 30px !important;
    padding: 10px !important;
  }

  .main-header-front {
    margin-bottom: 0px !important;
    height: auto !important;
  }

  .navbar-light .navbar-nav.menu_nav .nav-link {
    color: var(--blue) !important;
    font-size: 0.8rem !important;
    padding: 4px 8px !important;
  }


  a.nav-link.btnPrimary {
    font-size: 0.7rem !important;
    padding: 6px 8px !important;
  }

  .navbar-light .navbar-nav.right-nav .nav-link.linktext {
    margin: 0 5px !important;
    font-size: 0.7rem !important;
    padding: 6px 25px !important;
  }

  .react-player {
    width: 100% !important;
    /* height: 4/80px !important; */
  }

  /* under mantanence */
  .underMantanence {
    height: 100vh;
    min-height: auto !important;
  }

  .underMantanence .overlay {
    width: 750px !important;
    height: 750px !important;
    min-height: auto !important;
  }

  .underMantanence .overlay .overlay1 {
    padding: 60px !important;
    width: 100% !important;
    height: 100% !important;
  }



  .underMantanence .text_section h3 {
    font-size: 2.5rem !important;
    text-align: center;
    margin: 20px 0 !important;
  }

  .underMantanence .text_section p {
    font-size: 1.5rem !important;
    text-align: center;
  }

  .affiliate_highlights_image {
    min-height: 500px !important;
    height: 500px !important;
    border: 1px solid red;
  }
}

@media (width:820px) {
  .main-top-section {
    margin-top: 30px !important;
    padding: 10px !important;
  }

  .btn.btn-default {
    margin-top: 25px !important;
  }
}

@media (max-width:845px) {
  .free-download-form .right-area .top-right h3 br {
    display: none;
  }
}

@media (width: 1024px) and (height: 600px) {
  .mega-iframe {
    height: 450px !important;
  }

  .ai-power-dashboard h1.heading {
    font-size: 40px !important;
  }

  .sep-vertical {
    height: 40px !important;
  }


}


/*  LG Screen */
@media (min-width: 992px) and (max-width: 1024px) {

  /*  */

  .navbar-light .navbar-nav.menu_nav .nav-link {
    color: var(--blue) !important;
    font-size: 0.7rem !important;
    padding: 4px 13px !important;
  }


  a.nav-link.btnPrimary {
    font-size: 0.7rem !important;
    padding: 8px 10px !important;
  }

  .navbar-light .navbar-nav.right-nav .nav-link.linktext {
    margin: 0 5px 0px 20px !important;
    font-size: 0.6rem !important;
  }

  /*  */

  .duration_button_section .duration_btn {
    min-width: 140px !important;
    margin-bottom: 10px;
    font-size: 0.7rem !important;
  }

  .duration_button_section .duration_btn.annually {
    margin-left: -30px !important;
  }

  .most_overlay h4 {
    font-size: 1rem;
  }

  .pricing_item .pricing_item_price .pricing .amount {
    font-size: 3rem !important;
  }

  .select_plan {
    font-size: 1rem !important;
  }

  .details {
    width: 100% !important;
  }

  .details h4 {
    font-size: 1rem !important;
  }

  .details ul li {
    padding: 2px 0 !important;
    font-size: 0.7rem !important;
  }

  .table-data-main.resp-table2 {
    overflow: scroll !important;
  }

  .main-header-front {
    margin-bottom: 0px !important;
    height: auto !important;
  }

  .react-player {
    width: 100% !important;
    height: 400px !important;
  }

  /* under mantanence */
  .underMantanence {
    height: 100vh;
    min-height: auto !important;
  }

  .underMantanence .overlay {
    width: 600px !important;
    height: 600px !important;
    min-height: auto !important;
  }

  .underMantanence .overlay .overlay1 {
    padding: 60px !important;
    width: 100% !important;
    height: 100% !important;
  }



  .underMantanence .text_section h3 {
    font-size: 2.5rem !important;
    text-align: center;
    margin: 20px 0 !important;
  }

  .underMantanence .text_section p {
    font-size: 1.5rem !important;
    text-align: center;
  }

  /* affiliate page */
  .affiliateTop {
    padding: 50px !important;
  }

  .affiliateTop .affiliate_top_text h1 {
    font-size: 3rem !important;
  }

  .affiliateTop .affiliate_top_text h3 {
    font-size: 1.8rem !important;
  }

  .affiliateTop .affiliate_top_text p {
    font-size: 1.5rem !important;
  }

  .affiliateTop .affiliate_top_image {
    height: 550px !important;
    width: 100% !important;
  }

  .affiliateHighlights {
    padding: 60px !important;
  }

  .affiliate_highlights_image {
    margin-bottom: 30px;
    min-height: 700px !important;
  }

  .affiliate_highlights_text_head {
    font-size: 2rem !important;
  }

  .affiliate_highlights_text {
    padding: 0 !important;
  }

  .affiliate_highlights_text ul li .list_text_head {
    font-size: 1.4rem !important;
  }

  .affiliate_highlights_text ul li .list_text_des {
    font-size: 1.2rem !important;
  }

  .affiliate_should_join {
    padding: 40px !important;
  }

  .affiliate_should_text p,
  .affiliate_should_text ul li {
    font-size: 1.2rem !important;
  }

  .radius_image_design {
    width: 100% !important;
    margin-top: 30px;
    min-height: 600px !important;
  }

  .affiliateForm {
    padding: 40px !important;
  }

  .affiliateFormDes {
    font-size: 1.2rem !important;
  }

  .affiliateFAQ {
    padding: 40px !important;
  }

  .affiliateFAQ h1 {
    font-size: 2.5rem !important;
  }
}

/*  MD Screen */
@media (min-width: 768px) and (max-width: 991px) {

  .navbar-light .navbar-nav.right-nav .nav-link::after {
    left: 0 !important;
  }

  .main-middle-section .t-box {
    height: auto !important;
  }

  /* pricing  */
  .pricing_item {
    padding: 10px;
  }

  .top_button_section ul {
    width: 100% !important;
  }

  .select_plan {
    width: 100% !important;
  }

  .pricing_item .pricing_item_price .details ul li {
    font-size: 1rem !important;
  }

  .react-tabs__tab-panel.search {
    height: auto !important;
  }

  .table-data-main.resp-table2 {
    overflow: scroll !important;
  }

  .main-header-front {
    margin-bottom: 0px !important;
    height: auto !important;
  }

  .main-bottom-section .message_text_image p {
    font-size: 1rem !important;
  }

  .btn-primary.main {
    min-width: 100% !important;
    max-width: 100% !important;
  }

  .main-bottom-section .headtext h4 {
    font-size: 2rem !important;
  }

  .desktop {
    flex-direction: row !important;
  }

  .react-player {
    width: 100% !important;
    height: 400px !important;
  }

  /* under mantanence */
  .underMantanence {
    height: 100vh;
    min-height: auto !important;
  }

  .underMantanence .overlay {
    width: 750px !important;
    height: 750px !important;
    min-height: auto !important;
  }

  .underMantanence .overlay .overlay1 {
    padding: 60px !important;
    width: 100% !important;
    height: 100% !important;
  }



  .underMantanence .text_section h3 {
    font-size: 2.3rem !important;
    text-align: center;
    margin: 20px 0 !important;
  }

  .underMantanence .text_section p {
    font-size: 1.4rem !important;
    text-align: center;
  }

  /* affiliate page */
  .affiliateTop {
    padding: 30px !important;
  }

  .affiliateTop .affiliate_top_text h1 {
    font-size: 3rem !important;
  }

  .affiliateTop .affiliate_top_text h3 {
    font-size: 1.8rem !important;
  }

  .affiliateTop .affiliate_top_text p {
    font-size: 1.5rem !important;
  }

  .affiliateTop .affiliate_top_image {
    height: 550px !important;
    width: 100% !important;
  }

  .affiliateHighlights {
    padding: 40px !important;
  }

  .affiliate_highlights_image {
    min-height: 550px !important;
    margin-bottom: 30px;
    border-radius: 100% !important;
  }

  .affiliate_highlights_text_head {
    font-size: 2rem !important;
  }

  .affiliate_highlights_text {
    padding: 0 !important;
  }

  .affiliate_highlights_text ul li .list_text_head {
    font-size: 1.4rem !important;
  }

  .affiliate_highlights_text ul li .list_text_des {
    font-size: 1.2rem !important;
  }

  .affiliate_should_join {
    padding: 40px !important;
  }

  .affiliate_should_text p,
  .affiliate_should_text ul li {
    font-size: 1.2rem !important;
  }

  .radius_image_design {
    width: 100% !important;
    margin-top: 30px;
    min-height: 500px !important;
  }

  .affiliateForm {
    padding: 40px !important;
  }

  .affiliateFormDes {
    font-size: 1.2rem !important;
  }

  .affiliateFAQ {
    padding: 40px !important;
  }

  .affiliateFAQ h1 {
    font-size: 2.5rem !important;
  }

  .powerTitle {
    margin-top: 20px !important;
    padding: 0 !important;
  }
}

/*  SM Screen */
@media (min-width: 576px) and (max-width: 767px) {
  .navbar-light .navbar-nav.right-nav .nav-link::after {
    left: 0 !important;
  }

  .main-header-front {
    margin-bottom: 0px !important;
    height: auto !important;
  }

  .desktop {
    flex-direction: row !important;
  }

  .navbar-light .navbar-nav.menu_nav .nav-link {
    padding: 1rem !important;
  }

  .main-top-section {
    margin-top: 30px !important;
    padding: 10px !important;
  }

  .main-middle-section .t-box {
    width: 70% !important;
    margin: 0 auto;
  }



  .table-data-main.resp-table2 {
    overflow: scroll !important;
  }

  .message_show {
    width: 90% !important;
    font-size: 0.8rem !important;
    line-height: 1.4 !important;
  }

  .btn-primary.main {
    min-width: 60% !important;
    max-width: 100% !important;
  }

  .react-player {
    width: 100% !important;
    min-height: 300px !important;
  }

  /* affiliate page */
  .affiliateTop {
    padding: 20px 10px !important;
  }

  .affiliateTop .affiliate_top_text h1 {
    font-size: 2rem !important;
    margin-top: 30px !important;
  }

  .affiliateTop .affiliate_top_text h3 {
    font-size: 1.4rem !important;
  }

  .affiliateTop .affiliate_top_text p {
    font-size: 1.2rem !important;
  }

  .affiliateTop .affiliate_top_image {
    height: 350px !important;
    width: 100% !important;
    border-radius: 100% !important;
  }

  .affiliate_highlights_image {
    min-height: 500px !important;
    background-color: rgba(255, 255, 255, 0.5);
    margin: 30px 0;
  }

  .affiliateHighlights {
    padding: 20px 10px !important;
  }


  .affiliate_highlights_text_head {
    font-size: 1.8rem !important;
  }

  .affiliate_highlights_text {
    padding: 0 !important;
  }

  .affiliate_highlights_text ul li .list_text_head {
    font-size: 1.4rem !important;
  }

  .affiliate_highlights_text ul li .list_text_des {
    font-size: 1.2rem !important;
  }

  .affiliate_should_join {
    padding: 20px !important;
  }

  .affiliate_should_text p,
  .affiliate_should_text ul li {
    font-size: 1.2rem !important;
  }

  .radius_image_design {
    width: 100% !important;
    margin-top: 30px;
    border-radius: 100% !important;
    min-height: 350px !important;
  }

  .affiliateForm {
    padding: 20px !important;
  }

  .affiliateFormDes {
    font-size: 1.2rem !important;
  }

  .affiliateFAQ {
    padding: 10px !important;
  }

  .affiliateFAQ h1 {
    font-size: 2rem !important;
  }


  /* under mantanence */
  .underMantanence {
    height: 100vh;
    min-height: auto !important;
  }

  .underMantanence .overlay {
    width: 750px !important;
    height: 750px !important;
    min-height: auto !important;
  }

  .underMantanence .overlay .overlay1 {
    padding: 60px !important;
    width: 100% !important;
    height: 100% !important;
  }



  .underMantanence .text_section h3 {
    font-size: 1.8rem !important;
    text-align: center;
    margin: 20px 0 !important;
  }

  .underMantanence .text_section p {
    font-size: 1.2rem !important;
    text-align: center;
  }

}

/*  ################# SM Screen   ################# */
@media (min-width: 481px) and (max-width: 576px) {

  /* navbar */
  .main-header-front {
    margin-bottom: 0px !important;
    height: auto !important;
  }

  .header-notification ul {
    flex-wrap: wrap-reverse;
  }

  .vedio-div {
    height: 500px !important;
  }

  .header-notification ul .dropdown {
    margin-bottom: 10px;
  }

  .navbar-light .navbar-nav.right-nav .nav-link::after {
    left: 0 !important;
  }

  .desktop {
    flex-direction: row !important;
  }

  .navbar-light .navbar-nav.menu_nav .nav-link {
    padding: 1rem !important;
  }

  .navbar-light .navbar-nav.right-nav.mobile .nav-link {
    font-size: 0.9rem;
    padding: 5px 20px !important;
  }

  /* 
  .navbar-brand img {
    width: 100px;
  } */

  .navbar-brand {
    width: 70%;
  }

  .filterNav {
    justify-content: center !important;
  }

  /* Feature */
  .main-top-section {
    /* margin-top: 250px !important; */
    padding: 10px !important;
  }

  .vedio-div {
    height: auto !important;
  }

  .main-top-section h3 {
    font-size: 1.8rem;
  }

  .footer {
    height: auto !important;
  }

  /* pricing  */

  /* property search */
  .react-tabs__tab-panel.search {
    height: auto !important;
  }

  .table-data-main.resp-table2 {
    overflow: scroll !important;
  }

  .message_show {
    width: 90% !important;
    font-size: 0.8rem !important;
    line-height: 1.4 !important;
  }

  .dashboard_footer {
    padding: 10px;
    height: auto !important;
  }

  .search-container {
    height: auto !important;
  }

  .btn-primary.main {
    min-width: 60% !important;
    max-width: 100% !important;
  }

  .blog-detail {
    padding: 0 !important;
  }

  .blog-detail p {
    text-align: justify;
  }

  .blog_top_text {
    padding: 20px 10px !important;
  }

  .blog-detail ul li {
    text-align: justify;
  }

  .blogs_section .title_blogs {
    text-align: left;
  }

  .react-player {
    width: 100% !important;
    min-height: 300px !important;
  }


  /* under mantanence */
  .underMantanence {
    height: 100vh;
    min-height: auto !important;
  }

  .underMantanence .overlay {
    width: 600px !important;
    height: 600px !important;
    min-height: auto !important;
  }

  .underMantanence .overlay .overlay1 {
    padding: 50px !important;
    width: 100% !important;
    height: 100% !important;
  }



  .underMantanence .text_section h3 {
    font-size: 1.8rem !important;
    text-align: center;
    margin: 20px 0 !important;
  }

  .underMantanence .text_section p {
    font-size: 1.2rem !important;
    text-align: center;
  }

  /* affiliate page */
  .affiliateTop {
    padding: 20px 10px !important;
  }

  .affiliateTop .affiliate_top_text h1 {
    font-size: 2rem !important;
    margin-top: 30px !important;
  }

  .affiliateTop .affiliate_top_text h3 {
    font-size: 1.4rem !important;
  }

  .affiliateTop .affiliate_top_text p {
    font-size: 1.2rem !important;
  }

  .affiliateTop .affiliate_top_image {
    height: 350px !important;
    width: 100% !important;
    border-radius: 100% !important;
  }

  .affiliate_highlights_image {
    min-height: 500px !important;
  }

  .affiliateHighlights {
    padding: 20px 10px !important;
  }

  .affiliate_highlights_text_head {
    font-size: 1.8rem !important;
  }

  .affiliate_highlights_text {
    padding: 0 !important;
  }

  .affiliate_highlights_text ul li .list_text_head {
    font-size: 1.4rem !important;
  }

  .affiliate_highlights_text ul li .list_text_des {
    font-size: 1.2rem !important;
  }

  .affiliate_should_join {
    padding: 20px !important;
  }

  .affiliate_should_text p,
  .affiliate_should_text ul li {
    font-size: 1.2rem !important;
  }

  .radius_image_design {
    width: 100% !important;
    margin-top: 30px;
    border-radius: 100% !important;
    min-height: 350px !important;
  }

  .affiliateForm {
    padding: 20px !important;
  }

  .affiliateFormDes {
    font-size: 1.2rem !important;
  }

  .affiliateFAQ {
    padding: 10px !important;
  }

  .affiliateFAQ h1 {
    font-size: 2rem !important;
  }

  ul.footerLinks-copy {
    margin-bottom: 20px !important;
    justify-content: flex-start !important;
  }

  .ai-power-dashboard .trust-by.gray-theme iframe {
    min-height: 250px !important;
  }



}

/* #################  XS Screen ################# */
@media (min-width:408px) and (max-width:480px) {
  .auther_list {
    width: 100% !important;
  }

  .navbar-brand {
    width: 70% !important;
  }


  .header-notification ul {
    flex-wrap: wrap-reverse;
  }

  .header-notification ul .dropdown {
    margin-bottom: 10px;
  }

  /* register page */
  .signup_image_div {
    height: auto !important;
    min-height: 200px !important;
  }

  .signup_image_div h4 {
    font-size: 2rem !important;
  }

  /* navbar */
  .navbar-light .navbar-nav.right-nav .nav-link::after {
    left: 0 !important;
  }

  .desktop {
    flex-direction: row !important;
  }

  .navbar-light .navbar-nav.menu_nav .nav-link {
    padding: 1rem !important;
  }

  .navbar-light .navbar-nav.right-nav.mobile .nav-link {
    font-size: 0.9rem;
    padding: 5px 20px !important;
  }

  /* 
  .navbar-brand img {
    width: 130px;
  } */

  .navbar-brand {
    width: 80%;
  }

  .filterNav {
    justify-content: center !important;
  }

  /* Feature */
  .main-top-section {
    margin-top: 50px !important;
    padding: 10px !important;
  }

  .main-top-section h3 {
    font-size: 1.2rem;
  }

  .footer {
    height: auto !important;
  }

  /* pricing  */
  .pricing_item {
    padding: 10px;
  }

  .top_button_section ul {
    width: 100% !important;
  }

  .select_plan {
    width: 100% !important;
  }

  .pricing_item .pricing_item_price .details ul li {
    font-size: 1rem !important;
  }


  /* property search */
  .react-tabs__tab-panel.search {
    height: auto !important;
  }

  .table-data-main.resp-table2 {
    overflow: scroll !important;
  }

  .message_show {
    width: 90% !important;
    font-size: 0.8rem !important;
    line-height: 1.4 !important;
  }

  .dashboard_footer {
    flex-direction: column;
    padding: 10px;
    height: auto !important;
  }

  .search-container {
    height: auto !important;
  }

  ul.footerLinks-copy li {
    padding-right: 5px !important;
    margin-right: 0 !important;
    font-size: 0.8rem;
  }

  .main-header-front {
    margin-bottom: 0px !important;
    height: auto !important;
  }

  /* contact  */
  .header-inner h1 {
    font-size: 2rem !important;

  }

  .btn-primary.main {
    width: 100% !important;

  }

  .header-inner p {
    font-size: 1rem !important;
    width: 80%;
  }

  .blog-detail {
    padding: 0 !important;
  }

  .blog-detail p {
    text-align: justify;
  }

  .blogs_section {
    padding: 10px !important;
  }

  .blogs_section .top_blog_section {
    padding: 0 !important;
  }

  .blog_top_text {
    padding: 10px !important;
  }

  .blogs_section .title_blogs {
    text-align: center;
  }

  .terms_and_condition .term_box {
    margin: 0 !important;
    padding: 5px !important;
  }

  .header-inner2 h3 {
    font-size: 1.8rem !important;
  }

  .header-inner2 h3,
  .header-inner2 h1 {
    font-size: 1.4rem !important;
  }

  .header-inner2 {
    min-height: 250px !important;
    padding: 10px;
  }




  .react-player {
    width: 100% !important;
    height: auto !important;
    /* min-height: 300px !important; */
  }

  /* under mantanence */
  .underMantanence {
    height: 100vh;
    min-height: auto !important;
  }

  .underMantanence .overlay {
    width: 600px !important;
    height: 600px !important;
    min-height: auto !important;
  }

  .underMantanence .overlay .overlay1 {
    padding: 50px !important;
    width: 100% !important;
    height: 100% !important;
  }



  .underMantanence .text_section h3 {
    font-size: 1.8rem !important;
    text-align: center;
    margin: 20px 0 !important;
  }

  .underMantanence .text_section p {
    font-size: 1.2rem !important;
    text-align: center;
  }

  /* affiliate page */
  .affiliateTop {
    padding: 20px 10px !important;
  }

  .affiliateTop .affiliate_top_text h1 {
    font-size: 2rem !important;
    margin-top: 30px !important;
  }

  .affiliateTop .affiliate_top_text h3 {
    font-size: 1.4rem !important;
  }

  .affiliateTop .affiliate_top_text p {
    font-size: 1.2rem !important;
  }

  .affiliateTop .affiliate_top_image {
    height: 350px !important;
    width: 100% !important;
    border-radius: 100% !important;
  }

  .affiliate_highlights_image {
    min-height: 300px !important;
    border-radius: 100% !important;
  }

  .affiliateHighlights {
    padding: 20px 10px !important;
  }

  .affiliate_highlights_image {
    min-height: 500px !important;
    margin: 30px 0;
  }

  .affiliate_highlights_text_head {
    font-size: 1.8rem !important;
  }

  .affiliate_highlights_text {
    padding: 0 !important;
  }

  .affiliate_highlights_text ul li .list_text_head {
    font-size: 1.4rem !important;
  }

  .affiliate_highlights_text ul li .list_text_des {
    font-size: 1.2rem !important;
  }

  .affiliate_should_join {
    padding: 20px !important;
  }

  .affiliate_should_text p,
  .affiliate_should_text ul li {
    font-size: 1.2rem !important;
  }

  .radius_image_design {
    width: 100% !important;
    margin-top: 30px;
    border-radius: 100% !important;
    min-height: 350px !important;
  }

  .affiliateForm {
    padding: 20px !important;
  }

  .affiliateFormDes {
    font-size: 1.2rem !important;
  }

  .affiliateFAQ {
    padding: 10px !important;
  }

  .affiliateFAQ h1 {
    font-size: 2rem !important;
  }

  ul.footerLinks-copy {
    margin-bottom: 20px !important;
    justify-content: flex-start !important;
  }

  /* pre closure */

  .ai-power-dashboard iframe {
    min-height: 200px !important;
  }

  .powerTitle {
    font-size: 1rem !important;
    margin-top: 20px;
    padding-left: 0 !important;
  }

  body .ai-power-dashboard .trust-by.gray-theme h2 {
    font-size: 1.2rem !important;
    text-align: left;
  }

  .maximize-list li .maximize-list-div {
    max-width: 85% !important;
  }

  .ai-power-dashboard .box-feature h1 {
    margin-top: 10px;
  }
}

@media (min-width:381px) and (max-width:407px) {
  .desktop {
    flex-direction: row !important;
    padding: 0 !important;
  }

  .navbar-light .navbar-nav.right-nav .nav-link.linktext {
    padding: 8px 20px !important;
    margin: 5px 0 !important;
  }


  .react-player {
    width: 100% !important;
    height: auto !important;
    /* min-height: 300px !important; */
  }

  /* affiliate page */
  .affiliateTop {
    padding: 20px 10px !important;
  }

  .affiliateTop .affiliate_top_text h1 {
    font-size: 2rem !important;
    margin-top: 30px;
  }

  .affiliateTop .affiliate_top_text h3 {
    font-size: 1.4rem !important;
  }

  .affiliateTop .affiliate_top_text p {
    font-size: 1.2rem !important;
  }

  .affiliateTop .affiliate_top_image {
    height: 350px !important;
    width: 100% !important;
    border-radius: 100% !important;
  }


  .affiliateHighlights {
    padding: 20px 10px !important;
  }

  .affiliate_highlights_image {
    min-height: 500px !important;
    margin: 30px 0;
  }

  .affiliate_highlights_text_head {
    font-size: 1.8rem !important;
  }

  .affiliate_highlights_text {
    padding: 0 !important;
  }

  .affiliate_highlights_text ul li .list_text_head {
    font-size: 1.4rem !important;
  }

  .affiliate_highlights_text ul li .list_text_des {
    font-size: 1.2rem !important;
  }

  .affiliate_should_join {
    padding: 20px !important;
  }

  .affiliate_should_text p,
  .affiliate_should_text ul li {
    font-size: 1.2rem !important;
  }

  .radius_image_design {
    width: 100% !important;
    margin-top: 30px;
    border-radius: 100% !important;
    min-height: 350px !important;
  }

  .affiliateForm {
    padding: 20px !important;
  }

  .affiliateFormDes {
    font-size: 1.2rem !important;
  }

  .affiliateFAQ {
    padding: 10px !important;
  }

  .affiliateFAQ h1 {
    font-size: 2rem !important;
  }


  /* under mantanence */
  .underMantanence {
    height: 100vh;
    min-height: auto !important;
  }

  .underMantanence .overlay {
    width: 600px !important;
    height: 600px !important;
    min-height: auto !important;
  }

  .underMantanence .overlay .overlay1 {
    padding: 50px !important;
    width: 100% !important;
    height: 100% !important;
  }



  .underMantanence .text_section h3 {
    font-size: 1.8rem !important;
    text-align: center;
    margin: 20px 0 !important;
  }

  .underMantanence .text_section p {
    font-size: 1.2rem !important;
    text-align: center;
  }


  .blog-detail {
    padding: 0 !important;
  }

  .blog-detail p {
    text-align: justify;
  }

  .blogs_section {
    padding: 10px !important;
  }

  .blogs_section .top_blog_section {
    padding: 0 !important;
  }

  .blog_top_text {
    padding: 10px !important;
  }

  .blogs_section .title_blogs {
    text-align: center;
  }

  .terms_and_condition .term_box {
    margin: 0 !important;
    padding: 5px !important;
  }

  .header-inner2 h3 {
    font-size: 1.8rem !important;
  }

  .header-inner2 h3,
  .header-inner2 h1 {
    font-size: 1.4rem !important;
  }

  .header-inner2 {
    min-height: 250px !important;
    padding: 10px;
  }

  .top_button_section ul {
    width: 100% !important;
  }

  ul.footerLinks-copy {
    margin-bottom: 20px !important;
    justify-content: flex-start !important;
  }

  ul.footerLinks-copy li {
    line-height: 1.3 !important;
  }


  /* pre closure */

  .ai-power-dashboard iframe {
    min-height: 200px !important;
  }

  .powerTitle {
    font-size: 1rem !important;
    margin-top: 20px;
    padding-left: 0 !important;
  }

  body .ai-power-dashboard .trust-by.gray-theme h2 {
    font-size: 1.2rem !important;
    text-align: left;
  }

  .maximize-list li .maximize-list-div {
    max-width: 85% !important;
  }

  .ai-power-dashboard .box-feature h1 {
    margin-top: 10px;
  }

}


@media (max-width: 380px) {

  /* navbar */
  .newsletter .input_group button {
    font-size: 0rem !important;
    height: 40px;
    width: 40px !important;
  }

  .newsletter .input_group button svg {
    font-size: 2.1rem;
  }


  .tabs ul li a {
    padding: 15px 30px !important;
  }



  .navbar-light .navbar-nav.right-nav .nav-link.linktext {
    padding: 8px 20px !important;
    margin: 5px 0 !important;
  }

  /* register page */
  .signup_image_div {
    height: auto !important;
    min-height: 200px !important;
  }

  .signup_image_div h4 {
    font-size: 2rem !important;
  }

  .navbar-light .navbar-nav.right-nav .nav-link::after {
    left: 0 !important;
  }

  .desktop {
    flex-direction: column !important;
  }

  .desktop li a {
    width: 100%;
    margin-bottom: 10px;
  }

  .navbar-light .navbar-nav.menu_nav .nav-link {
    padding: 1rem !important;
  }

  /* Feature */
  .main-top-section h3 {
    font-size: 1.2rem;
  }

  .main-header-front .banner-content h3 {
    font-size: 2rem !important;
  }

  .main-header-front .banner-content {
    padding: 20px !important;
  }


  .tabs ul {
    flex-direction: column;
  }

  .tabs ul li a {
    width: 200px !important;
    border-radius: 30px !important;
    margin: 5px 0 !important;
  }

  .main-top-section {
    margin-top: 50px !important;
    padding: 10px !important;
  }

  .main-top-section h3 {
    font-size: 1rem !important;
  }

  .bottom-section {
    padding: 0 !important;
  }

  .main-middle-section h4 {
    font-size: 1rem !important;
    padding: 0;
  }

  .main-middle-section .t-box {
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .main-middle-section .t-box .text h6 {
    text-align: center !important;
  }

  .main-middle-section .t-box .text p {
    text-align: center !important;
  }

  .bottom-section .right {
    text-align: center !important;
  }

  .footer {
    height: auto !important;
  }

  /* pricing  */
  .pricing_item {
    padding: 10px;
  }

  .top_button_section ul {
    width: 100% !important;
  }

  .select_plan {
    width: 100% !important;
  }

  .pricing_item .pricing_item_price .details ul li {
    font-size: 1rem !important;
  }


  /* Billing  */
  .react-tabs__tab-panel h5 {
    font-size: 0.7rem !important;
  }

  .table_head p {
    font-size: 0.7rem;
  }

  .table_body span {
    font-size: 0.7rem !important;
    line-height: 1.2;
  }

  h3.sub_title {
    font-size: 1.2rem !important;
  }

  .term_checkbox label {
    font-size: 0.7rem !important;
    line-height: 1.2;
  }

  .invoice_table h3 {
    font-size: 1.2rem !important;
  }

  h4.sub_title_h4 {
    font-size: 1rem !important;
  }

  .billing_detail_section_des h4 {
    font-size: 0.9rem !important;
  }

  .billing_detail_section_des ul li {
    line-height: 1.2;
    font-size: 0.7rem !important;
  }

  /* header */
  .header-notification ul {
    flex-wrap: wrap-reverse;
  }

  .header-notification ul li {
    margin-bottom: 10px;
  }

  .remain_downloads {
    display: none !important;
  }

  .remain_skiptrace {
    display: none !important;

  }

  /* property search */
  .react-tabs__tab-panel.search {
    height: auto !important;
  }

  .react-tabs__tab-list .react-tabs__tab {
    text-align: left !important;
  }

  .searchAddress .tabs_top_main .react-tabs__tab-list .react-tabs__tab {
    width: 100% !important;
  }


  .footer .socialLinks ul {
    width: 100% !important;
  }

  ul.footerLinks-copy {
    justify-content: flex-start !important;
    margin-bottom: 20px !important;
  }

  ul.footerLinks-copy li {
    padding-right: 5px !important;
    margin-right: 0 !important;
    font-size: 0.8rem;
    display: flex;
    /* flex-direction: column; */
    align-items: flex-start;
    border: none;
  }


  /* Sign Text */
  .signup_image_div .text-div {
    bottom: 10px !important;
  }


  /* message show */
  .message_show {
    width: 90% !important;
    font-size: 0.8rem !important;
    line-height: 1.4 !important;
  }

  .table-data-main.resp-table2 {
    overflow: scroll !important;
  }

  .validation_list li span {
    font-size: 0.5rem !important;
  }

  .dashboard_footer {
    flex-direction: column;
    padding: 10px;
    height: auto !important;
  }

  .search-container {
    height: auto !important;
  }

  .main-header-front {
    margin-bottom: 0px !important;
  }

  .blogs_section .title_blogs {
    text-align: center;
  }

  .header-inner p {
    font-size: 1rem !important;
    width: 80%;
  }

  .header-inner2 h3 {
    font-size: 1.6rem !important;
  }

  .react-player {
    width: 100% !important;
    height: auto !important;
  }


  /* under mantanence */
  .underMantanence {
    height: 100vh;
    min-height: auto !important;
  }

  .underMantanence .overlay {
    width: 600px !important;
    height: 600px !important;
    min-height: auto !important;
  }

  .underMantanence .overlay .overlay1 {
    padding: 50px !important;
    width: 100% !important;
    height: 100% !important;
  }

  .underMantanence .text_section img {
    width: 80%;
  }

  .underMantanence .text_section h3 {
    font-size: 1.2rem !important;
    text-align: center;
    margin: 20px 0 !important;
  }

  .underMantanence .text_section p {
    font-size: 1rem !important;
    text-align: center;
  }

  /* affiliate page */
  .affiliateTop {
    padding: 20px 10px !important;
  }

  .affiliateTop .affiliate_top_text h1 {
    font-size: 2rem !important;
    margin-top: 30px;
  }

  .affiliateTop .affiliate_top_text h3 {
    font-size: 1.4rem !important;
  }

  .affiliateTop .affiliate_top_text p {
    font-size: 1.2rem !important;
  }

  .affiliateTop .affiliate_top_image {
    height: 350px !important;
    width: 100% !important;
    border-radius: 100% !important;
  }

  .affiliateHighlights {
    padding: 20px 10px !important;
  }

  .affiliate_highlights_image {
    min-height: 500px !important;
    margin: 30px 0;
    border-radius: 100% !important;
  }

  .affiliate_highlights_text_head {
    font-size: 1.8rem !important;
  }

  .affiliate_highlights_text {
    padding: 0 !important;
  }

  .affiliate_highlights_text ul li .list_text_head {
    font-size: 1.4rem !important;
  }

  .affiliate_highlights_text ul li .list_text_des {
    font-size: 1.2rem !important;
  }

  .affiliate_should_join {
    padding: 20px !important;
  }

  .affiliate_should_text p,
  .affiliate_should_text ul li {
    font-size: 1.2rem !important;
  }

  .radius_image_design {
    width: 100% !important;
    margin-top: 30px;
    border-radius: 100% !important;
    min-height: 350px !important;
  }

  .affiliateForm {
    padding: 20px !important;
  }

  .affiliateFormDes {
    font-size: 1.2rem !important;
  }

  .affiliateFAQ {
    padding: 10px !important;
  }

  .affiliateFAQ h1 {
    font-size: 2rem !important;
  }




  .blog-detail {
    padding: 0 !important;
  }

  .blog-detail p {
    text-align: justify;
  }

  .blogs_section {
    padding: 10px !important;
  }

  .blogs_section .top_blog_section {
    padding: 0 !important;
  }

  .blog_top_text {
    padding: 10px !important;
  }

  .blogs_section .title_blogs {
    text-align: center;
  }

  .terms_and_condition .term_box {
    margin: 0 !important;
    padding: 5px !important;
  }

  .header-inner2 h3 {
    font-size: 1.8rem !important;
  }

  .header-inner2 h3,
  .header-inner2 h1 {
    font-size: 1.4rem !important;
  }

  .header-inner2 {
    min-height: 250px !important;
  }

  .auther_list {
    width: 100% !important;
  }

  /* pre closure */

  .ai-power-dashboard iframe {
    min-height: 200px !important;
  }

  .powerTitle {
    font-size: 1rem !important;
    margin-top: 20px;
    padding-left: 0 !important;
  }

  body .ai-power-dashboard .trust-by.gray-theme h2 {
    font-size: 1.2rem !important;
    text-align: left;
  }

  .maximize-list li .maximize-list-div {
    max-width: 85% !important;
  }

  .ai-power-dashboard .box-feature h1 {
    margin-top: 10px;
  }
}

@media (width:280px) {
  .react-player {
    width: 100% !important;
    height: auto !important;
  }

  .tabs ul {
    flex-direction: column !important;
  }

  .tabs ul li {
    width: 100% !important;
  }

  ul.footerLinks-copy li {
    padding-right: 5px !important;
    margin-right: 0 !important;
    font-size: 0.6rem;
  }

  ul.footerLinks-copy li a {
    font-size: 0.6rem;

  }

  .register_section .register_section_overlay .bg_image {
    height: 750px !important;
  }

  .vedio-div {
    height: auto !important;
  }

  .main-header-front {
    margin-bottom: 0px !important;
    height: auto !important;
  }

  .blogs_section {
    text-align: center;
  }

  .blogs_section h4 {
    margin-bottom: 15px;
  }

  .blogs_section .title_blogs {
    text-align: center;
  }

  .btn-primary.main {
    min-width: 100% !important;
    max-width: 100% !important;
  }

  .header-inner p {
    font-size: 0.9rem;
  }

  .blog-detail {
    padding: 0 !important;
  }

  .blog-detail ul li {
    text-align: justify;
  }

  .blogs_section {
    padding: 0 !important;

  }

  .top_blog_section {
    padding: 0 !important;
  }
}

@media (max-width:991px) {
  .footer .socialLinks ul {
    width: 100% !important;
    justify-content: flex-start;
  }

  /* .footer .footer_div {
    min-height: 570px;
  } */
}

@media(max-width:767px) {
  .height-spec {
    height: auto !important;
    width: 100%;
    object-fit: initial;
  }

  body .pd-l-8 {
    padding-left: 0 !important;
  }

  .surplus-funds.ai-power-dashboard .gradient-white {
    margin-bottom: 20px !important;

  }
}