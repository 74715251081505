@import url('fonts.css');
@import url('headers.css');
@import url('responsive.css');

.ai-power-dashboard * {
    font-family: 'Figtree', sans-serif !important;
}

:root {
    --darkblue: #0b2548 !important;
    --lighblue: #043a80 !important;
    --yellow: #b4975a;
    --lightsky: #EFFAFE;
    --blue: #002952;
    --gold: #C9922B;
    --text: #a3a3a3;
    --hero: 3rem;
    --heading: 2.5rem;
    --subhead: 1.4rem;
    --title: 1.2rem;
    --des: 1rem;

    --gray25: #FCFCFD;
    --gray50: #F9FAFB;
    --gray200: #EAECF0;
    --gray500: #667085;
    --gray600: #475467;
    --gray800: #1D2939;
}

:root {
    --yellow: #c9922b;
    --blue: #00274e;
    --yellow-shadow: #d38f0040;
    --blue-shadow: #00285157;
    --light-yellow: #d4af62;
    --sueprhero: 3.8rem;
    --bluelight: #F9FAFB;
    --light-gold: #D4AF62;
    --Gray-600: #475467;
    --Gray-800: #1D2939;
    --Gray-900: #101828;
}


.text-gray800 {
    color: var(--gray800) !important;
}

.text-gray500 {
    color: var(--gray500) !important;
}

.ai-power-dashboard img {
    max-width: 100%;
    height: auto;
}

.ai-power-dashboard .light-font {
    font-size: 15px;
    font-weight: 400;
}

.ai-power-dashboard .icon-accordion {
    width: 15px;
    float: right;
}

.ai-power-dashboard .icon-accordion:hover {
    opacity: .7;
    cursor: pointer;
    transition: .2s;
}

.accordion-slide .cont-accordion {
    background-color: var(--gray50);
    border: 1px solid var(--gray200);
    padding: 12px 24px;
    border-radius: 15px;
}

.accordion-slide .heading-accordion {
    margin: 10px 0;
}

/* .accordion-slide .heading-accordion:hover {
    transition: .2s;
    cursor: pointer;
    opacity: .7;
} */
.accordion-slide .heading-accordion h6 {
    margin: 0;
    font-size: 24px;
}

.accordion-slide .para-accordion {
    height: 0;
}

.accordion-slide .para-accordion p {
    margin-bottom: 0;
    transition: all 0.3s linear;
    opacity: 0;
}

.cont-accordion img {

    transition: all 0.4s linear;
}

.cont-accordion:hover img {
    transform: rotate(180deg);
}

.cont-accordion:hover .para-accordion {
    height: auto;
}

.cont-accordion:hover .para-accordion p {
    opacity: 1;
}


.outline-theme {
    background: transparent !important;
    border: 1px solid var(--darkblue) !important;
    border-radius: 100px;
    padding: 10px 15px;
    color: var(--darkblue) !important;
    box-shadow: none;
    width: 100%;
}

.outline-theme:hover {
    background-color: var(--darkblue) !important;
    color: white !important;
    box-shadow: none !important;
}

.accordion-slide .para-accordion p {
    color: #344054;
}


body {
    font-family: "Figtree"
}


h1.title {
    text-align: center;
    font-size: 56px;
    margin-bottom: 60px !important;
    font-weight: 600;
    font-weight: 600;
    color: var(--Gray-800);
}

.label-title {
    background-color: #F2F4F7;
    padding: 6px 20px;
    color: var(--Gray-600);
    border-radius: 40px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 30px;
    text-transform: uppercase;
}

.ai-power-dashboard button,
.ai-power-dashboard a.button-anchor {
    background-color: var(--blue);
    border-radius: 20px;
    color: #ffffff;
    display: block;
    font-size: var(--des);
    font-weight: 600;
    margin: 0 5px;
    padding: 12px 22px;
    text-align: center;
    border: 0;
    float: left;
    border-radius: 110px;
}

.ai-power-dashboard button.secondary {
    color: var(--blue);
    border: 1px solid var(--blue);
    background-color: transparent;
}

.ai-power-dashboard a.secondary {
    color: var(--blue);
    border: 1px solid var(--blue);
    background-color: transparent;
    font-size: var(--des);
    font-weight: 600;
    margin: 0 5px;
    padding: 12px 22px;
    text-align: center;
    border-radius: 110px;
}

.ai-power-dashboard a.secondary:is(:hover, :focus) {
    background-color: var(--gold) !important;
    color: #fff;
    border-color: var(--gold);
}

.ai-power-dashboard button.secondary:hover {
    border: 1px solid var(--gold) !important;
    color: white;
}

.ai-power-dashboard button:hover {
    background-color: var(--gold);
    transition: .2s;
    cursor: pointer;
}

.ai-power-dashboard .listing ul {
    list-style: none;
    padding: 0;
}

.ai-power-dashboard .listing ul li {
    color: var(--blue);
    font-size: var(--des);
    display: flex;
    margin-bottom: 10px;
}

.check_icon {
    display: flex;
    background-color: #fbf7ef;
    color: #c1a05a;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    padding: 5px;
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.ai-power-dashboard .md-view {
    display: flex;
}

.ai-power-dashboard .sm-view {
    display: none;
}

.ai-power-dashboard .center-btn-casual {
    left: 50%;
    position: relative;
    transform: translateX(-50%);
}

.ai-power-dashboard button.light-yellow-theme:hover {
    background-color: var(--yellow);
    cursor: pointer;
    transition: .2s;
}

.ai-power-dashboard button.light-yellow-theme {
    background: var(--gold);
    border: 1px solid var(--gold) !important;
}



.ai-power-dashboard h1.heading {
    font-size: var(--sueprhero);
    font-weight: 600;
}

.ai-power-dashboard .listing p {
    font-size: 18px;
    color: #344054;
}

.ai-power-dashboard .listing {
    margin: 20px 0;
}

.ai-power-dashboard .box-feature {
    background: var(--bluelight);
    border-radius: 30px;
    padding: 30px;
    margin-bottom: 30px;
}

.box-feature.feature-lg {
    min-height: 300px;
}

.ai-power-dashboard .box-feature h1 {
    font-size: var(--subhead);
    font-weight: 600;
}

.ai-power-dashboard .box-feature p {
    font-size: 20px;
    font-weight: 500;
    color: #475467;

}



.ai-power-dashboard .trust-by h1 {
    font-size: var(--heading);
    text-align: start;
    font-weight: 600;
}

.ai-power-dashboard .trust-by h2 {
    font-size: var(--des);
    text-align: start;
    font-weight: 400;
}

.ai-power-dashboard .trust-by h6 {
    font-size: var(--des);
    font-weight: 700;
    margin-bottom: 0;
    margin-top: 20px;
}

.ai-power-dashboard .trust-by h6~p {
    color: #9b9b9b !important;
}

.ai-power-dashboard .trust-by {
    border-radius: 64px;
    background: linear-gradient(59deg, #0F0F0F 2.75%, #2F2F2F 100.98%);
    padding: 56px;
    color: white;
    margin: 50px 0;
}

.ai-power-dashboard iframe {
    width: 100%;
    margin-top: 30px;
    border-radius: 30px;
}

span.light-col {
    opacity: .5;
    font-weight: 400;
}


.backHZ {
    background-image: url('./../img/horizontal.png');
    background-position: 100% 100%;
    height: 100%;
    background-attachment: fixed;
    object-fit: contain;
}

.description {
    font-size: 24px;
    color: #1D2939;
    font-weight: 600;
}

ul.maximize-list {
    list-style: none;
    padding: 0;
}

.maximize-list li .maximize-list-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 20px;
    max-width: 90%;

}

.maximize-list li .maximize-list-div h6 {
    color: #1d2939;
    font-weight: 500;
    font-size: 20px;
}

.maximize-list li .maximize-list-div .innerDiv p {
    color: #667085 !important;
    font-size: 18px !important;
    font-weight: 500;
    padding: 0;
}

.maximize-list li {
    width: 100%;
    float: left;
    margin-bottom: 20px;
    display: flex;
}

.maximize-list li span {
    background: var(--light-gold);
    padding: 13px 0;
    border-radius: 40px;
    font-weight: 600;
    color: #141414;
    float: left;
    margin-top: 0px;
    width: 40px;
    height: 40px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.maximize-list li p {
    float: left;
    padding: 0;
    margin: 0;
    color: var(--Gray-600);
    padding-left: 20px;
    font-size: var(--title);
    width: 90%;
    font-weight: 600;
    line-height: 1.3;
    vertical-align: middle;
}

.optimize-cont {
    padding: 50px;
    border: 1px solid #80808047;
    border-radius: 50px;
    margin: 30px 0;
}


.optimize-sec h1.left-sec {
    font-size: var(--hero);
    font-weight: 600;
}

.optimize-sec h2.left-sec {
    font-size: var(--heading);
    text-align: center;
    font-weight: 600;
    color: var(--Gray-800);
}

.optimize-sec h1.left-sec span,
.optimize-sec h2.left-sec span {
    color: var(--light-yellow);
}

.optimize-sec .right-sec {}

.optimize-sec .right-sec p {
    font-size: var(--des);
    font-weight: 500;
    color: var(--Gray-600);
}

.optimize-sec .right-sec p span {
    margin-top: 10px;
    display: block;
    color: var(--Gray-800);
}

.category-opt {}

.optimize-sec hr {
    width: 100%;
    margin: 80px 0;
}

.category-opt .box-category {}

.category-opt .box-category h1 {
    text-align: center;
    margin: 20px 0px;
    font-size: 72px;
    color: var(--Gray-900);
}

.category-opt .box-category p {
    font-size: var(--des);
    font-weight: 500;
    color: var(--Gray-600);
    text-align: center;
}

.ai-power-dashboard .bd-0 {
    border: 0 !important;
}

.ai-power-dashboard .pd-0 {
    padding: 0 !important;
}


.ai-power-dashboard .testimony-ai-power .box-category {
    padding: 40px;
    color: var(--Gray-600);
    margin-bottom: 40px;
    border-radius: 24px;
    background: linear-gradient(180deg, #F6F6F6 0%, #EFEFEF 100%);
}


.ai-power-dashboard .testimony-ai-power .box-category .feedback-auth {
    width: 100%;
    display: flex;
}

.ai-power-dashboard .testimony-ai-power .box-category .imgDiv {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background-color: #d9d9d9;
}

.ai-power-dashboard .testimony-ai-power .box-category .imgDiv svg {
    font-size: 30px;
    color: var(--blue);
}

.ai-power-dashboard .testimony-ai-power .box-category .feedback-auth img {
    width: 100%;
    height: 100%;
}

.ai-power-dashboard .testimony-ai-power .box-category .feedback-auth .box {
    padding-left: 10px;
    vertical-align: middle;
    padding-top: 5px;
}

.ai-power-dashboard .testimony-ai-power .box-category .feedback-auth .box h6 {
    color: var(--Gray-800);
}

.ai-power-dashboard .testimony-ai-power .box-category .feedback-auth .box p {
    color: var(--Gray-600);
}

.ai-power-dashboard .testimony-ai-power .box-category h6.height-5 {
    height: 70px;
    color: var(--Gray-600);
}

.ai-power-dashboard .property-chart {
    padding: 64px;
    border-radius: 64px;
    background: var(--Gradient, linear-gradient(39deg, #002952 42.9%, #003C77 100%));
}

.ai-power-dashboard .property-chart h1 {
    color: white;
}

.ai-power-dashboard .property-chart img.prop-chart {
    width: 100%;
}

.ai-power-dashboard .property-chart button.primary {
    background: transparent;
}

.ai-power-dashboard .property-chart button.secondary {
    color: white;
    border: 1px solid white;
}

.blogs-ai .box-ai {
    border-radius: 24px;
    padding: 24px;
    background: var(--Gray-50, #F9FAFB);
    float: left;
    width: 100%;
}

.ai-power-dashboard .outline-none {
    border: 0 !important;
}

.blogs-ai .box-ai h6 {
    width: 80%;
    float: left;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: var(--title);
    color: #000;
}

.blogs-ai .box-ai img.post-image {
    margin-bottom: 20px;
    border-radius: 15px;
}

.blogs-ai .box-ai span {
    width: 28px;
    float: right;
    border: 0;
    border-radius: 50px;
    padding: 0;
    height: 28px;
    border: 0;
    cursor: pointer;
}

.blogs-ai .box-ai span img.icon-next {
    width: 100%;
    border: 0;
}

.blogs-ai .box-ai:hover {
    cursor: pointer;
    background-color: var(--blue);
    color: white;
    transition: .2s;
}

.blogs-ai .box-ai:hover h6 {
    color: #fff;
}

.hover {
    cursor: pointer;
}

.ai-power-dashboard .newsletter {
    background-image: url('./../img/newsletter.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 40px;
    padding: 30px;
}

.ai-power-dashboard .newsletter h1 {
    color: var(--Gray-800, #1D2939);
    /* font-family: Figtree; */
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.ai-power-dashboard .newsletter input {
    border-radius: 100px;
    border: 0;
    padding: 12px 20px;
    width: 100%;
    color: var(--Gray-500, #667085);
    /* font-family: Figtree; */
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 120% */
}

.ai-power-dashboard .newsletter button {}

.ai-power-dashboard .grid-img {
    background-image: url('./../img/grid.png');
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: contain;
}

.ai-power-dashboard .gradient-white {
    background: linear-gradient(50deg, #ffffff -17.25%, #ffffff87 100.98%);
}

.ai-power-dashboard .logo-head {}

.ai-power-dashboard .img-scshot {
    width: 100%;
    border-radius: 20px;
}

.ai-power-dashboard .maxim-img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.ai-power-dashboard .height-maxi-img {
    height: 270px;
    position: relative;
    background-size: cover;
    object-fit: cover;
}





.ai-power-dashboard header {
    border: 0 !important;
    margin: 0;
    padding: 0;
}

.ai-power-dashboard-footer .social:hover {
    opacity: .9;
    transition: .2s;
}

.ai-power-dashboard-footer .listing-foot li.anchor a:hover {
    opacity: 1;
    transition: .2s;
}

.ai-power-dashboard-footer .social:hover:before {
    content: "";
    width: 32px;
    height: 32px;
    background-color: #0000004f;
    display: block;
    border-radius: 100px;
}

.mega-iframe {
    width: 100% !important;
    overflow: hidden;
    border-radius: 30px;
}

.ai-power-dashboard header button {
    padding: 6px 22px;
}

.ai-power-dashboard header ul li a {
    color: var(--Gray-600);
}

.ai-power-dashboard .wd-92 {
    width: 92%;
}

.ai-power-dashboard header ul li a:hover {
    color: var(--gold);
    transition: .2s;
}

.ai-power-dashboard .m-r-0 {
    margin-right: 0;
}

.ai-power-dashboard header .right-pd-0 {
    padding-right: 0;
}

.ai-power-dashboard .bd-r-grey {
    border-right: 2px solid #5B5B5B;
}

.ai-power-dashboard-footer {
    background: linear-gradient(38.54deg, #002952 42.9%, #003C77 100%);
}

.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.ai-power-dashboard-footer img {
    margin-bottom: 30px;
    width: 85%;
}

.ai-power-dashboard-footer ul.social-ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.ai-power-dashboard-footer {
    padding: 50px;
}

.ai-power-dashboard-footer .social {
    float: left;
    width: 32px;
    position: relative;
    height: 32px;
    background-image: url('./../img/social.png');
    cursor: pointer;
    background-repeat: no-repeat;
    margin-right: 8px;
    z-index: 0;
}

.ai-power-dashboard-footer .facebook {}

.ai-power-dashboard-footer .twitter {
    background-position: -48px 0;
}

.ai-power-dashboard-footer .linkedin {
    background-position: -96px 0;
}

.ai-power-dashboard-footer .youtube {
    background-position: -144px 0;
}

.ai-power-dashboard-footer .insta {
    background-position: -192px 0;
}

.ai-power-dashboard-footer .tiktok {
    background-position: -240px 0;
}


.ai-power-dashboard-footer {}

.ai-power-dashboard-footer .listing-foot {
    list-style: none;
    padding: 0;
    margin: 0;
    color: white;
    /* margin-top: 20px; */
}

.ai-power-dashboard-footer .listing-foot li.head-des {
    margin-bottom: 10px;
}


.ai-power-dashboard-footer .listing-foot li a {
    font-weight: 300;
    opacity: .8;
    font-size: calc(var(--des) - 2px);
    cursor: pointer;
    font-family: "Figtree";
}

.modal-backdrop.show {
    opacity: 0.9 !important;
}

body .gray-theme {
    background: var(--gray50) !important;
}

body .ai-power-dashboard .trust-by.gray-theme h2 {
    color: var(--gray600);
    line-height: 1.6;
    font-size: 20px;
}

.ai-power-dashboard .trust-by.gray-theme iframe {
    height: 100%;
    width: 100%;
    min-height: 350px;
    max-height: 400px;
    margin-top: 0;
}

.ai-power-dashboard .bd-l-grey {
    border-left: 3px solid #5B5B5B;
}


/* New Css Code  */
.paddingSection {
    padding: 50px 10px;
}