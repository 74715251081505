@media (min-width: 1200px) {
    .mega-iframe {
        height: 600px !important;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .mega-iframe {
        height: 400px !important;
    }

    .ai-power-dashboard .bd-l-grey {
        border: 0 !important;
    }
}

@media (min-width: 768px) {
    .bd-placeholder-img-lg {
        font-size: 3.5rem;
    }

    .ai-power-dashboard .lazy-load-image-background {
        width: 100% !important;
        height: auto !important;
    }

    img.slide-image-wall {
        height: 100%;
    }
}

@media (min-width: 577px) and (max-width: 768px) {
    .mega-iframe {
        height: 380px !important;
    }

    .ai-power-dashboard .lazy-load-image-background {
        width: 100% !important;
        height: auto !important;
    }

    img.slide-image-wall {
        height: 100%;
    }

}

@media (min-width: 480px) and (max-width: 576px) {
    .mega-iframe {
        height: 280px !important;
    }

    .ai-power-dashboard .lazy-load-image-background {
        width: 100% !important;
        height: auto !important;
    }

    img.slide-image-wall {
        height: 100%;
    }

}


@media (max-width: 480px) {
    .ai-power-dashboard h1.heading {
        font-size: 2rem !important;
    }

    .mega-iframe {
        height: 200px !important;
    }

    .ai-power-dashboard .lazy-load-image-background {
        width: 100% !important;
        height: auto !important;
    }

    img.slide-image-wall {
        height: 100%;
    }

    .ai-power-dashboard .property-chart button {
        width: 100%;
    }

    .ai-power-dashboard button,
    .ai-power-dashboard a.button-anchor {
        width: 100%;
        margin-bottom: 10px !important;
    }
}




@media (max-width: 768px) {

    body .ai-power-dashboard .md-view {
        display: none;
    }

    body .ai-power-dashboard .sm-view {
        display: flex;
    }

    .ai-power-dashboard h1.title {
        font-size: 30px !important;
        margin-bottom: 30px !important;
    }

    .ai-power-dashboard header ul li {
        float: left;
        width: 100%;
    }

    .ai-power-dashboard header ul {
        padding: 0 10px;
        font-weight: bold;
    }

    .ai-power-dashboard .resp-tog {
        width: 20px;
        height: 20px;
    }

    .ai-power-dashboard a.button-anchor {
        border-radius: 30px !important;
    }


    .ai-power-dashboard .newsletter h1,
    .ai-power-dashboard .newsletter input,
    .ai-power-dashboard .newsletter button {
        margin-bottom: 15px;
    }

    body .ai-power-dashboard-footer {
        padding: 45px 15px;
    }

    .ai-power-dashboard-footer img {
        margin-bottom: 30px;
        width: 70%;
    }

    .ai-power-dashboard-footer .col-12 {
        margin-bottom: 15px;
    }

    .ai-power-dashboard .slide-image-wall {
        /* display: none; */
        margin-top: 50px;
    }

    body .ai-power-dashboard .sep-vertical {
        height: 40px;
    }



    body .ai-power-dashboard .trust-by h1,
    body .ai-power-dashboard .trust-by h2 {
        font-size: 1.25rem !important;
    }

    body .ai-power-dashboard .trust-by h2 {
        width: 100%;
        display: block;
    }

    .ai-power-dashboard .bd-l-grey {
        border: 0 !important;
    }

    body .ai-power-dashboard .trust-by br {
        display: none;
    }


    body .optimize-sec hr {
        width: 100%;
        margin: 30px 0;
    }

    body .category-opt {
        width: 100%;
        margin: 0;
    }

    body .ai-power-dashboard .testimony-ai-power {
        padding: 0;
    }

    body .ai-power-dashboard .newsletter h1 {
        text-align: center;
    }

    body .ai-power-dashboard .trust-by span.light-col {
        text-align: center;
        width: 100%;

    }

    /* .newsletter h4 {
        font-size: 20px;
    } */

    body .ai-power-dashboard .trust-by,
    body .ai-power-dashboard .optimize-cont,
    body .ai-power-dashboard .property-chart {
        padding: 24px;
        margin: 0;
        border-radius: 24px;
    }

    body .ai-power-dashboard .box-feature {
        border-radius: 24px;
    }

    body .ai-power-dashboard .bd-r-grey {
        border-right: 0;
    }

    body .ai-power-dashboard .optimize-sec {
        margin: 0;
    }

    body .ai-power-dashboard .testimony-ai-power .box-category {
        margin-bottom: 10px;
    }

    .ai-power-dashboard .property-chart button.secondary {
        margin-bottom: 20px;

    }

    .ai-power-dashboard .blogs-ai .box-ai {
        margin-bottom: 20px;
    }

    body .ai-power-dashboard .newsletter h1 br {
        display: none;
    }

    body .ai-power-dashboard .newsletter button {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    body .ai-power-dashboard-footer .listing-foot li.head-des {
        margin-top: 30px;
    }

    body .ai-power-dashboard-footer img {
        width: 75%;
    }

    body #mobile-nav {
        display: none;
        padding: 0;
    }

    body #mobile-nav.show {
        display: block;
        transition: .2s;
    }

    body .pd-sm-0 {
        padding: 0 !important;
    }

    .ai-power-dashboard h1.heading {
        font-size: 2.3rem
    }

    .accordion-slide .heading-accordion h6 {
        font-size: 18px !important;
    }


}